import React from "react";
import { Box, Heading, Flex, Text, Button, Link, Icon } from "@chakra-ui/core";
import mountainbikeicon from '../assets/mountainbikeicon.png'
import { mainEmailLink, mainEmail } from '../util/Constants'

const MenuItems = (props:any) => (
    <Link href={props.href}>
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block" color="green.500">
    {props.children}
  </Text>
  </Link>
);

const Header = (props:any) => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);
  const hideHeader = () => {
    if(window.scrollY > 150){
    setShow(false)
    }
  };

  React.useEffect(()=> {
    window.addEventListener('scroll', hideHeader);
  },[])

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="green.900"
      color="white"
      {...props}
    >

      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg">
        { <img className="header-logo" src={mountainbikeicon} alt={"banner"} />}
        </Heading>
        <Heading as="h2" size="lg">
        Pomeroy Cycles
        </Heading>
      </Flex>

      <Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ xs: show ? "block" : "none", sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        {/* <MenuItems href={mainContactPhoneLink}><Icon name="phone" mx="2px" />{mainContactPhone}</MenuItems> */}
        <MenuItems href={mainEmailLink}><Icon name="email" mx="2px" /> {mainEmail}</MenuItems>
      </Box>

      <Box
        display={{ xs: show ? "block" : "none", sm: show ? "block" : "none", md: "flex" }}
        mt={{ base: 4, md: 0 }}
      >
        {/* <Link target="_blank" href="https://instagram.com/atlantic_coast_de?r=nametag">        
          <Button bg="transparent">
            Photos
          </Button>
        </Link> */}
        <Link href={mainEmailLink}>
          <Button bg="transparent" border="1px">
            Contact Us Now!
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};

export default Header;