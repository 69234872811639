import React from 'react';
import './App.css';
import Header from './components/Header';
import mainbackground from './assets/mainbackground.png'
import WindowBox from './components/WindowBox'
import { Box, IconButton, Link, Heading, Text, Divider } from '@chakra-ui/core';
import { mainEmailLink } from 'util/Constants';
import 'font-awesome/css/font-awesome.min.css';


function App() {
  return (
    <div className="App">
      <Header className="header-bar" />
      <div className="main-grid">
        <header className="header-section">
        </header>
        <div className="main-section">
          {<img className="main-back-img" src={mainbackground} alt={"logo"} />}
        </div>
        <div className="window-section">
          <WindowBox />
        </div>
        <Box className="bottom-banner-section" bg="black">
          <div className="bottom-banner-details">
            <Heading color="white" size="xl">More Trail, Less Bale</Heading>
            <Text fontSize="2xl"></Text>
          </div>
        </Box>
        <div className="extended-section">
        <Heading  size="xl">Let us help you with all things bikes...</Heading>
        <Divider />
        <Text fontSize="xl">From purchasing your next cycle, to keeping it on the trail, to finding your next trail, we can help.
          We carry knowledge and experience that will ensure the best possible experience for you.
          Give us a call and let us know how we can serve you!
        </Text>

        </div>
        <Box className="footer-section">
        {/* <Link target="_blank" href="https://instagram.com/atlantic_coast_de?r=nametag"><i className="fa fa-instagram" ></i></Link> */}
          <Text fontSize="sm">2023 Polmeroy Cycles All rights reserved</Text>
        </Box>
      </div>

      <div className="action-icons">
        <div className="action-icon">
          <Link display={{ xs: "block", sm: "block", md: "none", lg: "none" }} href={mainEmailLink}>
            <IconButton
              variantColor="green"
              aria-label="Call Segun"
              size="lg"
              icon="email"
              isRound
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
