import * as React from 'react'
import { Box, Text, Icon, Link } from '@chakra-ui/core';
import {mainEmailLink } from 'util/Constants';

const WindowBox = (props:any)=> {
    return (
    <div>
        <Link href={mainEmailLink}>
        <Box rounded="md" color="green.900" bg="white" className="bullets">
        <BulletPoint text="Used Bikes"/>
        <BulletPoint text="Bike Parts & Repairs"/>
        <BulletPoint text="Community Knowledge"/>
        </Box>
        </Link>
        <Link href={mainEmailLink}>
        <Box rounded="full" color="green.900" bg="white" className="window-banner-1">
        <Text textAlign="center" padding="5% 0px" fontSize="xl">Safety, Quality, Value</Text>
        </Box>
        </Link>
    </div>)
}

export default WindowBox


const BulletPoint = (props:any) => {
    return   <Text textAlign="left" padding="5% 0px" fontSize="xl"><Icon name="chevron-right" />{props.text}</Text>
}